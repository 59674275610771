import React, { useEffect, useState } from 'react'

import { useCameraValidationTest } from '../hooks/useCameraValidationTest'
import { useEditCamera } from '../hooks/useEditCamera'
import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { useLiveStreamSetup } from '@/hooks/useLiveStreamSetup'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box, ModalBody, ModalFooter, Spacer, Tooltip } from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'

import { DeviceModalHeader } from '../../components/DeviceModalHeader'
import { DeviceTable } from '../../components/DeviceTable'
import { CameraFormDataTypeI } from '../../types/types'
import { isPlaced } from '../../utils/utils'
import { ValidateCameraStreamResults } from '../components/ValidateCameraStreamResults'
import { getCameraInputs, validateMac } from '../utils/utils'
import { EditCameraLiveStreamingFields } from './EditCameraLiveStreamingFields'

interface EditCameraFormIProps {
  onClose: () => void
  deviceId: string
}

export const EditCameraForm = ({ onClose, deviceId }: EditCameraFormIProps) => {
  const form = useForm<CameraFormDataTypeI>({
    mode: 'onChange',
  })

  const hasCameraTestFeatureFlag = useFeatureFlag(FeatureFlag.cameraTestUtil)
  const {
    handleSubmit,
    errors,
    register,
    control,
    setValue,
    formState: { dirtyFields },
  } = form

  const { isOrangePeel } = useLiveStreamSetup()
  const { shouldEnableUpdateDevice } = usePermissions()
  const {
    handleSetLiveStreamFields,
    shouldShowLiveStreamFields,
    cameraData,
    isLoading,
    floorsOptions,
    isUpdateDeviceLoading,
    isUpdateCameraLoading,
    updateCamera,
  } = useEditCamera(deviceId)

  const cameraValidationTest = useCameraValidationTest()
  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<CameraFormDataTypeI> = async (values) => {
    const formData = {
      id: deviceId,
      name: values.name,
      floorId: values.location?.value,
      macAddress: values.macAddress?.length > 0 ? values.macAddress : null,
    }
    const cameraData = getCameraInputs(deviceId, values)
    try {
      await updateCamera(formData, cameraData, dirtyFields)
      showSuccess('Camera updated.')

      /// Reset so that the isDirty flag is reset
      form.reset(values)

      // After we save the creds we want to wipe whatever existing test from screen
      // NOTE! There's a bug rn where stream info will be cached for ~15min, so
      // technically if users re-test there's a good chance they'll get a false
      // positive/negative based on old results. That's to be addressed in ticket XYZ
      cameraValidationTest.reset()
    } catch (e) {
      // TODO - Look into a more helpful error. e.x. when testing I put in "Jack's test camera" and instead of
      //  telling me that I couldn't have a ' it said "Something went wrong"...
      //  Could look to do a regex matching like we use for MAC address?
      showError()
    }
  }

  const onValidateCameraClick = () => {
    cameraValidationTest.validateCameraStream(deviceId)
  }

  useEffect(() => {
    if (!isLoading) {
      setValue('name', cameraData?.device?.name, { shouldValidate: true })
      setValue(
        'location',
        floorsOptions?.find((i) => i.value === cameraData?.device?.floor?.id),
        {
          shouldValidate: true,
        }
      )
      setValue('macAddress', cameraData?.device?.macAddress)
      setValue('externalId', cameraData?.device?.camera?.externalId)
    }
  }, [isLoading, floorsOptions])

  const hasCameraTestResults = cameraValidationTest.finalResults !== undefined

  // This is a working solution that scrolls to the bottom of the Camera Test results.
  // Would be nice to get to smoothly scroll & animate, but not going to have that hold this up
  useEffect(() => {
    if (hasCameraTestResults) {
      const modalBody = document.querySelector('#editCameraModalBody')
      if (modalBody) {
        modalBody.scrollTop = modalBody.scrollHeight
      }
    }
  }, [hasCameraTestResults])

  // Should mirror isDirty, but is updated in real time vs. isDirty needs to be fetched
  const [hasFormContentChanged, setHasFormContentChanged] = useState(false)

  useEffect(() => {
    setHasFormContentChanged(form.formState.isDirty)
  }, [form.formState.isDirty])

  return (
    <>
      <DeviceModalHeader
        subTitle='Enter Device Info to connect to a Device'
        title='Edit Camera'
      />
      <ModalBody id='editCameraModalBody' p='0'>
        {!cameraData || isLoading ? (
          <SkeletonModalBody />
        ) : (
          <>
            <form>
              <Box px='6'>
                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editCameraModal_name'
                    errorMessage={errors.name && errors.name.message}
                    id='name'
                    inputRef={register({
                      required: 'Device Name is required',
                    })}
                    isInvalid={!!errors.name}
                    label='Device Name'
                    placeholder='Enter the Device Name'
                  />
                </Box>
                <Box mb='3'>
                  <FormInputSelectControl
                    control={control}
                    data-testid='devicesPage_editCameraModal_location'
                    defaultValue={null}
                    errorMessage='Select a device location'
                    id='location'
                    isClearable
                    isDisabled={isLoading || isPlaced(cameraData?.device)}
                    isInvalid={!!errors?.location}
                    label='Device Location'
                    options={floorsOptions}
                    placeholder='Select Device Location'
                    rules={{ required: true }}
                    tooltipText={
                      isPlaced(cameraData?.device) &&
                      'Device must not be placed to update device location'
                    }
                  />
                </Box>
                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editCameraModal_macAddress'
                    errorMessage={
                      errors.macAddress && errors.macAddress.message
                    }
                    id='macAddress'
                    inputRef={register({
                      validate: (v) => validateMac(v),
                      required: false,
                    })}
                    isInvalid={!!errors?.macAddress}
                    label='Enter MAC Address'
                    placeholder='Enter MAC Address'
                  />
                </Box>

                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editCameraModal_externalId'
                    errorMessage={errors.externalId?.message}
                    id='externalId'
                    inputRef={register()}
                    label='External ID'
                    placeholder='Enter External ID'
                  />
                </Box>
                {shouldShowLiveStreamFields && (
                  <EditCameraLiveStreamingFields
                    cameraData={cameraData}
                    form={form}
                    isLoading={isLoading}
                  />
                )}
                {!shouldShowLiveStreamFields && isOrangePeel && (
                  <Box display='flex' justifyContent='center' p='20px'>
                    <Button onClick={handleSetLiveStreamFields}>
                      Set Live Streaming
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
            <DeviceTable devices={[cameraData?.device as unknown]} />
            {hasCameraTestFeatureFlag && hasCameraTestResults && (
              <ValidateCameraStreamResults
                errorMessages={cameraValidationTest.finalResults.errorMessages}
                url={cameraValidationTest.finalResults.url}
              />
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter py='4'>
        {hasCameraTestFeatureFlag && (
          <>
            <Tooltip
              hasArrow
              isDisabled={cameraValidationTest.isTestingCamera}
              label={
                hasFormContentChanged
                  ? 'Save changes before testing'
                  : 'Test camera config'
              }
              placement='left'
            >
              <span>
                <Button
                  isDisabled={hasFormContentChanged}
                  isLoading={cameraValidationTest.isTestingCamera}
                  onClick={onValidateCameraClick}
                  paddingX='32px'
                  variant='outline'
                >
                  Test
                </Button>
              </span>
            </Tooltip>

            <Spacer />
          </>
        )}

        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!shouldEnableUpdateDevice}
          isLoading={isUpdateDeviceLoading || isUpdateCameraLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Saving Changes'
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </ModalFooter>
    </>
  )
}
