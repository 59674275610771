import { useMemo } from 'react'

import {
  useDevicesUnpairedQuery,
  useEdgeDeviceQuery,
  useUpdateEdgeDeviceMutation,
} from '@/graphql/generated/hooks'
import { UpdateEdgeDeviceInput } from '@/graphql/generated/schemas'

import { getDeviceOptions, getEdgeDeviceOptions } from '../utils/utils'

export const useEditGateway = (id: string) => {
  const { data, loading: isLoading } = useDevicesUnpairedQuery({
    fetchPolicy: 'cache-and-network',
  })
  const { data: deviceData, loading: isDeviceLoading } = useEdgeDeviceQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  })
  const [updateEdgeDevice, { loading: isUpdateLoading }] =
    useUpdateEdgeDeviceMutation()

  // All options that could be added to the gateway
  const devicesOptions = useMemo(() => getDeviceOptions(data), [data])

  // Options currently selected
  const devices = useMemo(() => getEdgeDeviceOptions(deviceData), [deviceData])

  const update = async (formData: UpdateEdgeDeviceInput) => {
    await updateEdgeDevice({
      variables: {
        input: formData,
      },
      refetchQueries: ['GatewayDevices'],
    })
  }

  return {
    deviceData,
    devices,
    devicesOptions,
    isLoading,
    update,
    isUpdateLoading,
    isDeviceLoading,
  }
}
