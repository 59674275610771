import { useEffect, useMemo, useState } from 'react'

import { useLiveStreamSetup } from '@/hooks/useLiveStreamSetup'
import { DeepMap, FieldValues } from 'react-hook-form'

import {
  useDeviceCameraEditQuery,
  useFloorsQuery,
  useUpdateCameraMutation,
  useUpdateDeviceMutation,
} from '@/graphql/generated/hooks'

import { UpdateCameraInputsI, UpdateDeviceInputsI } from '../../types/types'
import { getFloorOptions } from '../../utils/utils'
import {
  isDeviceFieldsDirty,
  isLiveFieldsDirty,
  isLiveStreamingSetup,
} from '../utils/utils'

export const DEFAULT_MASKED_PASSWORD = 'DEFAULT_PASSWORD'

export const useEditCamera = (deviceId: string) => {
  const { isOrangePeel } = useLiveStreamSetup()
  const [shouldShowLiveStreamFields, setShowLiveStreamFields] =
    useState(isOrangePeel)
  const { data: floorsData, loading: isFloorsLoading } = useFloorsQuery({
    fetchPolicy: 'network-only',
  })
  const {
    data: cameraData,
    loading: isCameraLoading,
    error: cameraError,
  } = useDeviceCameraEditQuery({
    variables: {
      id: deviceId,
    },
    fetchPolicy: 'network-only',
  })
  const [updateDevice, { loading: isUpdateDeviceLoading }] =
    useUpdateDeviceMutation()

  const [updateCameraDevice, { loading: isUpdateCameraLoading }] =
    useUpdateCameraMutation()

  const floorsOptions = useMemo(() => getFloorOptions(floorsData), [floorsData])

  useEffect(() => {
    if (cameraData) {
      setShowLiveStreamFields(isLiveStreamingSetup(cameraData, isOrangePeel))
    }
  }, [cameraData])

  const handleSetLiveStreamFields = () => {
    setShowLiveStreamFields(true)
  }

  const update = async (formData: UpdateDeviceInputsI) => {
    try {
      await updateDevice({
        variables: {
          input: formData,
        },
        refetchQueries: ['Devices'],
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  const updateCamera = async (
    formData: UpdateDeviceInputsI,
    cameraData: UpdateCameraInputsI,
    dirtyFields: DeepMap<FieldValues, true>
  ) => {
    try {
      if (isDeviceFieldsDirty(dirtyFields)) {
        await update(formData)
      }
      if (isLiveFieldsDirty(dirtyFields)) {
        await updateCameraDevice({
          variables: {
            input: cameraData,
          },
        })
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  return {
    shouldShowLiveStreamFields,
    handleSetLiveStreamFields,
    floorsOptions,
    cameraData,
    cameraError,
    isLoading: isCameraLoading || isFloorsLoading,
    isUpdateDeviceLoading,
    isUpdateCameraLoading,
    update,
    updateCamera,
  }
}
