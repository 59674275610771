import React from 'react'

import { CameraValidationTestResultsIProps } from '../hooks/useCameraValidationTest'

import { HStack, Text, VStack } from '@chakra-ui/react'

import { ResultBadge } from '@/components/ui'
import { VideoPlayer } from '@/features/video-player'

const successBadgeMessage = 'SUCCESSFUL'
const errorBadgeMessage = 'FAILED'

export const ValidateCameraStreamResults = (
  testResults: CameraValidationTestResultsIProps
) => {
  const isValid = testResults.url !== undefined
  const badgeMessage = isValid ? successBadgeMessage : errorBadgeMessage

  return (
    <VStack align='start' paddingBottom='6' paddingX='6' w='100%'>
      <HStack paddingTop='4'>
        <Text fontWeight='bold'>Status</Text>
        <ResultBadge isValid={isValid} message={badgeMessage} />
      </HStack>

      {/* TODO - Figure out a way to not need to provide these super specific dimensions */}
      {isValid && (
        <VideoPlayer
          autoplay={true}
          liveEnabled={true}
          liveOnly={true}
          pipActive={false}
          placeholderHeight='290'
          placeholderWidth='525'
          showControls={false}
          showToggleLiveButton={false}
          url={testResults.url!}
        />
      )}

      {!isValid && testResults.errorMessages !== undefined && (
        <>
          <Text fontWeight='bold' paddingTop='2'>
            Errors
          </Text>

          {/*Here's how we can display many errors at once if we need to */}
          {testResults.errorMessages.map((value, id) => (
            <Text key={id} paddingStart={2}>
              - {value}
            </Text>
          ))}
        </>
      )}
    </VStack>
  )
}
