import { useEffect, useState } from 'react'

import { useCameraFeed } from '@/features/video-player'

// Should only ever have one of these things at a time
export interface CameraValidationTestResultsIProps {
  url?: string
  errorMessages?: string[]
}

export const useCameraValidationTest = () => {
  const [isTestingCamera, setIsTestingCamera] = useState(false)
  const [finalResults, setFinalResults] = useState<
    CameraValidationTestResultsIProps | undefined
  >(undefined)

  const [cameraIdToStream, setCameraIdToStream] = useState<string>('')

  const { url, errorMessage, isLoading } = useCameraFeed(cameraIdToStream)

  // TODO - Should wipe test results when something in the form changes! i.e. dirty

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (url) {
      setFinalResults({
        url: url,
        errorMessages: undefined,
      })
    } else if (errorMessage) {
      setFinalResults({
        url: undefined,
        errorMessages: [errorMessage],
      })
    }
    setIsTestingCamera(false)
    // TODO - Hacky, but seems like we have to clear it in able to be able to
    //  re-set it on button click to then re-test
    setCameraIdToStream('')
  }, [url, errorMessage, isLoading])

  /**
   * When a user clicks test we'll clear the existing data, set that we're testing, & set the new camera ID
   * @param cameraId
   */
  const validateCameraStream = (cameraId: string) => {
    setFinalResults(undefined)
    setIsTestingCamera(true)
    setCameraIdToStream(cameraId)
  }

  /**
   * Resets state back to default
   */
  const reset = () => {
    setFinalResults(undefined)
    setIsTestingCamera(false)
    setCameraIdToStream('')
  }

  return {
    validateCameraStream,
    isTestingCamera,
    finalResults,
    reset,
  }
}
